import { addClass, logger, removeClass } from '@ilc-technology/cefcom-utils'

// ********************************************************************************************
// Text rendering utilities
// ********************************************************************************************

// Get the label to render as a string from an array of labels and a slug
// This should be used in conjustion with React Safe library since
// sometime labels may include rich html text
// @labels: Array of objects representing the labels
// @slug: string - machine name usually coming from Sitecore of the label to use
// @replaceItems: Array of Objects representing the placeholder to replace with the value {value: '[[PLACEHOLDER]]', value: var}
// export const getLabel = (labels, slug, replaceItems) => {
//   try {
//     if (!labels || !labels.length) {
//       return '';
//     }
//     const obj = labels.find(o => o.slug === slug);
//     if (obj) {
//       if (obj.text && replaceItems && replaceItems.length) {
//         let label = obj.text;

//         // If an array of strings to be replaced
//         for (let i = 0; i < replaceItems.length; i++) {
//           const element = replaceItems[i];
//           const placeholder = element['label'];
//           const tag = element['tag'];
//           const textToReplace = typeof tag === 'string' ? `<${tag}>${element['value']}</${tag}>` : element['value'];

//           label = label.replace(placeholder, textToReplace);
//         }
//         return label;
//       }

//       return obj.text ? obj.text : '';
//     } else {

//       // Return an empty string if the label object is undefined
//       return '';

//     }
//   } catch (error) {
//     return '';
//   }

// };

// *******************************************************************
// Query Parameters Utilities
// *******************************************************************

export const getUrlParamByName = (param, q) => {
  let queryString = q
  if (!queryString) {
    return false
  }
  let variables = queryString.split('&')
  for (let i = 0; i < variables.length; i++) {
    let keyValuePair = variables[i].split('=')
    if (keyValuePair[0] === param) {
      return keyValuePair[1]
    }
  }
  return false
}

export const decodeFromUri = (str) => {
  try {
    return !str ? '' : decodeURIComponent((str + '').replace(/\+/g, '%20'))
  } catch (error) {
    return ''
  }
}

export const getProgramCodesByAgeGroup = (ageCode, ageGroups) => {
  const ageGroup = ageGroups.find((ageGroup) => ageGroup.code === ageCode)
  return ageGroup.programCodes
}

export const setHeaderTransparency = (isTransparent) => {
  try {
    if (!document) {
      return
    }
    const body = document.querySelector('html')
    if (isTransparent) {
      addClass(body, 'transparent')
      removeClass(body, 'not-transparent')
    } else {
      removeClass(body, 'transparent')
      addClass(body, 'not-transparent')
    }
  } catch (error) {}
}

/**
 * Sort array of objects based on another array
 * @array: The Array to sort
 * @order: an array representing the order eg ['four', 'two', 'one', 'three']
 * @key: String the key to use to identify the order
 *
 * Example:
 * const ordered_array = mapOrder(item_array, item_order, 'id');
 **/

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    let A = a[key]
    let B = b[key]

    // if (order === ['']) {
    //   console.log(array);
    // }
    return order.indexOf(A) > order.indexOf(B) ? 1 : -1
  })

  return array
}

export const fireClickMap = () => {
  try {
    if (
      typeof window !== undefined &&
      window.EFClickMapActivator.init() &&
      typeof window.EFClickMapActivator.init().init === 'function'
    ) {
      window.EFClickMapActivator.init().init()
    }
  } catch (error) {
    // console.log(error);
  }
}

// Utility function to check if a program is recently visited
export const isRecentlyVisitedProgram = (currentSlug, recentPrograms) => {
  const normalizedRecentPrograms = recentPrograms.map((card) => card.toLowerCase())
  return normalizedRecentPrograms.includes(currentSlug.toLowerCase())
}
