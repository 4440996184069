import React, { ReactElement } from 'react'
import DefaultRendering from '../templates/Default'

// components Mapping
import FatFooterRendering from '../renderings/FatFooterRendering'
import FatFooterWithoutImageRendering from '../renderings/FatFooterWithoutImageRendering'
import PreFooterRendering from '../renderings/PreFooterRendering'
import AgeCategoriesRendering from '../renderings/AgeCategoriesRendering'
import FunnelingComponentRendering from '../renderings/FunnelingComponentRendering'
import StageRendering from '../renderings/StageRendering'
import SectionWithBigImagesRendering from '../renderings/SectionWithBigImagesRendering'
import HighlightsRendering from '../renderings/HighlightsRendering'
import SubTilesRendering from '../renderings/SubTilesRendering'
import LanguageTilePopUpComponentRendering from '../renderings/LanguageTilePopUpComponentRendering'
import USTravelRendering from '../renderings/USTravelRendering'
import USStudyComponent from '../renderings/USStudyComponentRendering'
import USCombinedTileBanner from '../renderings/USBannerRendering'
import USOverview from '../renderings/USOverview'
import USPGCard from '../renderings/USPGCard'
import IkeaText from '../renderings/IkeaText'
const renderings = new Map()

const Components = {
  default: DefaultRendering,
  // Sections
  stage: StageRendering,
  'stage-text-only': StageRendering,
  'stage-text-only-w-logo': StageRendering,
  'pre-footer': PreFooterRendering,
  'fat-footer': FatFooterRendering,
  'fat-footer-without-image': FatFooterWithoutImageRendering,
  'fat-footer-with-background-and-logo': FatFooterRendering,
  'age-categories': AgeCategoriesRendering,
  // 'section-with-bg-image': SectionWithBigImagesRendering, I don't think we use this
  highlights: HighlightsRendering,
  'funneling-tiles': FunnelingComponentRendering,
  'funneling-sub-tiles': SubTilesRendering,
  'funneling-tile-pop-up': LanguageTilePopUpComponentRendering,

  // US Renderings
  'us-tiles-w-links': USTravelRendering,
  'us-funneling-tiles': USStudyComponent,
  'us-single-tile': USCombinedTileBanner,
  'us-fat-footer': FatFooterRendering,
  overview: USOverview,
  educators: USPGCard,
  'host-families': USPGCard,

  //E1
  'tiles-w-links': USTravelRendering,
  'tiles-w-buttons': USTravelRendering,
  'mosaic-funneling-tiles': USStudyComponent,
  'ikea-text': IkeaText,
}

interface IBlok {
  _uid?: string
  rendering?: string
  page?: { rendering: string }
}

const getComponent = (blok: IBlok, data: any) => {
  // console.log('blok >', blok)
  // console.log('data >', data)

  if (blok && typeof Components[blok.rendering] !== 'undefined') {
    return React.createElement(Components[blok.rendering], { content: blok, data })
  }
  // CHeck for Page Component
  if (
    blok &&
    blok.page &&
    blok.page.rendering &&
    typeof Components[blok.page.rendering] !== 'undefined'
  ) {
    return React.createElement(Components[blok.page.rendering], {
      key: blok._uid,
      content: blok,
      data,
    })
  }
  // console.log(data)
  console.error(
    `Bucket: ${data && data.mc} Slug: ${
      data && data.slug
    } | Could not find the rendering to render check the rendering is not undefined and it is matching the correct mapping`,
  )
  throw new Error(
    `Bucket: ${data && data.mc} Slug: ${
      data && data.slug
    } | Could not find the rendering to render check the rendering is not undefined and it is matching the correct mapping`,
  )
  return null //React.createElement(() => handleError('Cannot find the "component" or "rendering" for this page. Ensure the service data is not undefined and it structure is correct'))
}

export { getComponent }
