import React, { Component } from 'react'
import { VerticalCard } from '@ilc-technology/cefcom-teaser-card'
import { GridRow, GridCol } from '@ilc-technology/cefcom-ui-library'
import { IHighlights } from '../../../types'
import './styles.scss'

class HighlightsRendering extends Component<IHighlights> {
  render() {
    const {
      data: { mc },
      content,
      content: { title, rendering, cards, description },
    } = this.props
    return (
      <div>
        <section
          data-clicksubregion='highlights'
          id={`omnidiv-${rendering}`}
          className={`ef-section rendering__${rendering}`}
        >
          <div className='cefcom-container -horizontal-left text-center'>
            <GridRow classNames={['-x-center']}>
              <div className='block-text'>
                <h2 dangerouslySetInnerHTML={{ __html: title }} className='ef-section-main-title' />
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </GridRow>
            <GridRow
              classNames={[
                `${cards.length > 2 ? '-x-left-margin' : '-x-center '}   ${
                  rendering === 'card-generic-linear-mobile-scroll' ? ' ' : ''
                }`,
              ]}
            >
              {cards.map((card, i) => {
                const { anchorUrl, anchorText, title, description, images, isExternal } = card

                return (
                  <GridCol
                    classNames={[
                      'block-cards',
                      '-s-12',
                      '-m-6',
                      `${cards.length > 2 ? '-l-4 ' : '-xl-5'}`,
                    ]}
                    key={i}
                  >
                    <VerticalCard
                      classNames={'home-courses__cards'}
                      description={description}
                      title={title}
                      images={images}
                      anchorUrl={anchorUrl}
                      anchorText={anchorText}
                      isExternal={isExternal}
                    />
                  </GridCol>
                )
              })}
            </GridRow>
          </div>
        </section>
      </div>
    )
  }
}

export default HighlightsRendering
