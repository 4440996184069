import React from 'react'
import { connect } from 'react-redux'
import { closeProgramsPopup, selectProgramCode } from '../../redux/actions'
import {
  derivatedAvailableProgramsSelector,
  selectedTileGroupSelector,
  notSureCardSelector,
  isProgramsPopUpOpen,
  selectServiceLabels,
  ageGroupsSelector,
  selectedAgeGroupSelector,
  selectedSubTileSelector,
  isHeaderTransparent,
} from '../../redux/selectors'
import { GridContainer, GridRow, GridCol } from '@ilc-technology/cefcom-ui-library'
import { NotSureCard } from '@ilc-technology/cefcom-teaser-card'
import { ProgramCardHorizontal } from '@ilc-technology/cefcom-program-card'
import { getProgramCodesByAgeGroup, fireClickMap, isRecentlyVisitedProgram } from '../../utils'
import { getAvailableGridCols } from '../../utils/columnChecker'
import { getLabel, getNestedVals } from '@ilc-technology/cefcom-utils'
import { HOMEPAGE_SLUG, POPUP_SLUG } from '../../redux/constants'
import CefcomOptimizely from '../../../utils/hoc/CefcomOptimizely'
import OptimizelyUseProgramCardsWrapper from '../OptimizelyUseProgramCardsWrapper'

import './style.scss'

class ProgramCardsPopup extends React.Component {
  constructor(props) {
    super(props)
    this.checkProgramInHash = this.checkProgramInHash.bind(this)
    this.closePopupOnLoad = this.closePopupOnLoad.bind(this)
    this.handleClickBack = this.handleClickBack.bind(this)
    this.state = {
      recentPrograms: [],
    }
  }

  componentDidMount() {
    this.checkProgramInHash()
    this.closePopupOnLoad()
    fireClickMap()

    // Check if window is defined (ensures code only runs on the client-side)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window !== 'undefined' && window.dl && window.dl.user && window.dl.user.activity) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const allRecentPrograms = window.dl.user.activity.recentProgram || []
      // Get the first three recent programs
      const recentPrograms = allRecentPrograms.slice(0, 3)
      this.setState({
        recentPrograms,
      })
    }
  }

  componentDidUpdate() {
    fireClickMap()
  }

  checkProgramInHash() {
    try {
      if (location && location.hash) {
        const ageCode = location.hash.replace(`#${POPUP_SLUG}`, '')
        this.props.selectProgramCode(
          ageCode,
          getProgramCodesByAgeGroup(ageCode, this.props.ageGroups),
        )
      }
    } catch (error) {
      // console.error(error.message);
    }
  }

  closePopupOnLoad() {
    // Close the Popup
    try {
      window.onhashchange = () => {
        const hash = location.hash.replace('#', '')
        if (
          (hash === '' && this.props.isProgramsPopUpOpen) ||
          (hash === HOMEPAGE_SLUG && this.props.isProgramsPopUpOpen)
        ) {
          this.props.closeProgramsPopup()
        }
        this.checkProgramInHash()
      }
    } catch (error) {
      // console.log(error);
    }
  }

  handleClickBack(e) {
    const checkTransparent = this.props.data.page.isTransparent
    this.props.closeProgramsPopup(checkTransparent)

    if (this.props.selectedAgeGroup.code === 'PG-ALL') {
      const state = { scrollTarget: 'omnidiv-funneling-us-tiles' }
      window.history.pushState(state, null, window.location.pathname)

      // Scroll to the element after updating the history state
      setTimeout(() => {
        const element = document.getElementById(state.scrollTarget)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }

  render() {
    const rendering = 'program-cards'
    const { recentPrograms } = this.state

    const {
      data,
      pgCards,
      labels,
      notSureCard,
      selectedAgeGroup,
      isProgramsPopUpOpen,
      subTileContent,
      showOnBrochure,
      ageGroups,
      agePrograms,
      countryLabel,
    } = this.props

    let popupTitle = ''
    let popupShortDescription = ''
    if (isProgramsPopUpOpen) {
      // popupTitle =
      //   selectedAgeGroup.popupTitle !== undefined
      //     ? selectedAgeGroup.popupTitle
      //     : subTileContent.popupSection.popupTitle
      const isAll = selectedAgeGroup.slug === 'all' || selectedAgeGroup.slug === 'pg-all'
      popupTitle =
        selectedAgeGroup.title !== undefined && !isAll
          ? getLabel(labels, 'programs-for')
          : getLabel(labels, 'all-programs')

      popupShortDescription = isAll ? '' : selectedAgeGroup.title

      // popupShortDescription =
      //   selectedAgeGroup.popupShortDescription !== undefined
      //     ? selectedAgeGroup.popupShortDescription
      //     : subTileContent.popupSection.popupShortDescription
    }

    const filterCode = selectedAgeGroup.slug
    return (
      <div style={{ display: isProgramsPopUpOpen ? 'block' : 'none' }}>
        <section
          id={`omnidiv-${rendering}`}
          className={`ef-section rendering__${rendering}`}
          data-clicksubregion='brochure'
        >
          <div className={`program-cards-top`}>
            <GridContainer>
              <GridRow>
                <GridCol classNames={['-sm-12', '-m-12']}>
                  <span className={'link-back'} onClick={this.handleClickBack}>
                    {getLabel(labels, 'back')}
                  </span>
                </GridCol>
              </GridRow>
            </GridContainer>
          </div>

          <GridContainer>
            <GridRow classNames={['program-cards__header-row']}>
              <GridCol classNames={['-sm-12', '-m-12', 'program-cards__header-col']}>
                <div className='program-cards__header'>
                  <p
                    className='program-cards__header-label'
                    dangerouslySetInnerHTML={{ __html: popupTitle }}
                  />
                  <h2
                    className='program-cards__header-title'
                    dangerouslySetInnerHTML={{ __html: popupShortDescription }}
                  />
                </div>
              </GridCol>
            </GridRow>
          </GridContainer>
          <div className={`section-wrapper filter-${filterCode}`}>
            <GridContainer>
              <GridRow>
                {/* {.map((card, i) => {
                  const compareProgramCode =
                    card.agePrograms &&
                    card.agePrograms.length &&
                    card.agePrograms[0].code === selectedAgeGroup.code
                  const isRecent = isRecentlyVisitedProgram(card.code, recentPrograms) */}

                {/* return ( */}
                {/* <React.Fragment key={card.code}> */}
                <CefcomOptimizely
                  config={{ flagKey: 'pg_funnelling_recently_viewed_card', centralData: data }}
                >
                  <OptimizelyUseProgramCardsWrapper
                    card={pgCards}
                    selectedAgeGroup={selectedAgeGroup}
                    recentPrograms={recentPrograms}
                    // siteUrl={compareProgramCode ? card.agePrograms[0].siteUrl : card.siteUrl}
                    // showRecentlyVisitedTag={isRecent}
                    // recentlyVisitedTagLabel={card.recentlyVisitedTagLabel}
                    showAgeDifferentiator={
                      //PLEASE DO NOT DELETE this was added for the program pop up page when you click the subtiles
                      selectedAgeGroup.code === 'ALL' || selectedAgeGroup.code === 'PG-ALL'
                    }
                  />
                </CefcomOptimizely>
                {/* </React.Fragment>
                  )
                })} */}

                <GridCol
                  classNames={[
                    ` program-card-wrapper card -s-12 ${
                      getAvailableGridCols(pgCards.length, true).m
                    } -l-12`,
                  ]}
                >
                  <NotSureCard
                    {...notSureCard}
                    siteUrl={notSureCard.brochureUrl}
                    siteBtnLabel={notSureCard.brochureLabel}
                    brochureBtnLabel={getLabel(labels, 'order-brochure')}
                    dataClickSubregion='pg-cards'
                  />
                </GridCol>
              </GridRow>
            </GridContainer>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  pgCards: derivatedAvailableProgramsSelector(state),
  notSureCard: notSureCardSelector(state),
  ageGroups: props.ageGroups,
  selectedAgeGroup: selectedAgeGroupSelector(state),
  isProgramsPopUpOpen: isProgramsPopUpOpen(state),
  labels: selectServiceLabels(state),
  subTileContent: selectedSubTileSelector(state),
  transparentStatus: isHeaderTransparent(state),
  // programCardPosition: programCardPositionSelector(state)
})
const mapDispatchToProps = (dispatch) => ({
  closeProgramsPopup: (checkTransparent) => dispatch(closeProgramsPopup(checkTransparent)),
  selectProgramCode: (ageCode, programCodes) => dispatch(selectProgramCode(ageCode, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCardsPopup)
