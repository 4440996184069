/*
 ************************************************************************************************
 * Keys and IDs
 ************************************************************************************************
 */

export const GTM_ID = 'GTM-K57G4W'

export const SIMPLIFIED_MARKETS = ['gr', 'il', 'az', 'ro', 'lv', 'si', 'hu']
export const defaultAgeGroupCode = 'all_default'
export const userDefinedAllCode = 'all_user-defined'
